import useAtlasInstances from "hooks/useAtlasInstances";
import { useSelector } from "react-redux";
import { useGetAreasQuery } from "store/services/areas";
import { useGetConfigsQuery } from "store/services/config";
import { useGetLookupByNameQuery } from "store/services/lookups";
import { useGetSitesQuery } from "store/services/sites";
import { selectAtlasInstance } from "store/slices/authSlice";
import { LOOKUPS } from "store/slices/lookupSlice";


const useRequiredDataLoadingGuad = () => {
  const atlasInstance = useSelector(selectAtlasInstance)
  const { isLoading: configsLoading } = useGetConfigsQuery(undefined, {skip: !atlasInstance});
  const { isLoading: isLoadingSites } = useGetSitesQuery(undefined, {skip: !atlasInstance});
  const { isLoading: isLoadingAreas } = useGetAreasQuery(undefined, {skip: !atlasInstance});
  const { isLoading: productionLinesLoading } = useGetLookupByNameQuery(
    LOOKUPS.PRODUCTION_LINES, {skip: !atlasInstance}
  );
  const {isLoadingUserInstances} = useAtlasInstances();

  const dataLoading =
    configsLoading ||
    isLoadingSites ||
    isLoadingAreas ||
    productionLinesLoading || 
    isLoadingUserInstances;

  return dataLoading;
};

export default useRequiredDataLoadingGuad;
