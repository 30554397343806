import { IEmployee } from "store/models/employee.model";
import { EmployeesQueryResponse } from "store/services/employee";


import { createMongoId } from "utils/createMongoId";

export function initials(employee: IEmployee) {
  const firstIntial = employee.firstName[0] || "";
  const secondInitial = employee.lastName[0] || "";
  return firstIntial + secondInitial;
}

export function fullName(employee: IEmployee | EmployeesQueryResponse) {
  return `${employee.firstName} ${employee.lastName}`;
}



export function employeeAlreadyAssignedToEmployeeStation(
  employee: IEmployee,
  stationId: IEmployee["station"]
) {
  return stationId === employee.station;
}

export function wasRollCalledToday(employee: IEmployee) {
  const startOfToday = new Date(new Date().setUTCHours(0, 0, 0, 0));
  return new Date(employee.lastRollCall) > startOfToday;
}

export function wasNotRollCalledToday(employee: IEmployee) {
  return !wasRollCalledToday(employee);
}

export function assigned(employee: IEmployee) {
  return Boolean(employee.station);
}

export function isUnassigned(employee: IEmployee) {
  return !employee.station && employee.nullStationReason === "Unassigned";
}

export function initEmployee(
  employeeProps: Partial<IEmployee> = {}
): IEmployee {
  return {
    _id: createMongoId(),
    lastName: "",
    firstName: "",
    active: true,
    station: null,
    lastStation: null,
    lastRollCall: new Date(),
    nullStationReason: 'Unassigned',
    qualifications: [],
    goneForever: false,
    personnelType: "",
    seniorityDate: new Date(),
    email: "",
    employeeNumber: "",
    ...employeeProps,
  };
}
