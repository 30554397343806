import React  from "react";
import async from "utils/AsyncLoadComponent";
import Work from "@mui/icons-material/Work";
import ViewCompact from "@mui/icons-material/ViewCompact";
import Tune from "@mui/icons-material/Tune";
import Group from "@mui/icons-material/Group";
import SettingWheel from "@mui/icons-material/Settings";
import ElectricBolt from "@mui/icons-material/ElectricBolt";
import Layers from "@mui/icons-material/Layers";
import Assignment from "@mui/icons-material/Assignment";
import MenuBook from "@mui/icons-material/MenuBook";
import { alphabetizeByProperty } from "utils/formatUtils";
import usePermissions, {
  ActionType,
  FeatureType,
} from "utils/permissions/usePermissions";
import { useGetSitesQuery } from "store/services/sites";
import { useSelector } from "react-redux";
import { selectAtlasInstance, selectLoggedInUser } from "store/slices/authSlice";
import { useGetUserInstancePermissionQuery } from "store/services/instances";

// Auth components
const SignIn = async(() => import("auth/SignIn"));
const Page404 = async(() => import("routes/Page404"));
const Page500 = async(() => import("routes/Page500"));

// Dashboard components
const IssuesDashboard = async(
  () => import("features/1 - dashboard/IssuesDashboard")
);


// Team Components
const Employees = async(() => import("features/7 - team/employees/Employees"));
const SkillsMatrix = async(() => import("features/7 - team/skillMatrix/Skills"));
const SkillSearch = async(() => import("features/7 - team/skillSearch/SkillSearch"))
const AvailableTraining = async(() => import("features/7 - team/availableTraining/ManageAvailableTraining"))

// Inventory Components
const MaterialsPage = async(
  () => import("features/6 - inventory/materials/Materials")
);

// System Components
const UsersPage = async(() => import("features/8 - system/users/Users"));
const AppPermissions = async(
  () => import("features/8 - system/permissions/AppPermissions")
);

// Site components
const ShopMap = async(() => import("features/2 - shopMap/ShopMap"))

const Projects = async(() => import("features/4 - projects/Projects"));
const Project = async(() => import("features/4 - projects/Project"));
const Plans = async(() => import("features/5 - plans/Plans"));
const Tasks = async(() => import("features/5 - plans/Tasks"));
const Plan = async(() => import("features/5 - plans/Plan"))
const CreatePlan = async(() => import("features/5 - plans/CreatePlan"));
const PlanPresets = async(() => import("features/5 - plans/PlanPresets"));

const Zippy = async(() => import("features/3 - zippy/Zippy"));
const Profile = async(() => import("features/10 - profile/Profile"));

// Learning Components
const BestKnownMethods = async(
  () => import("features/9 - learning/bestKnownMethods/BestKnownMethods")
);


// External route
const ExternalRoute = async(() => {});

export const useRoutesConfig = () => {
  const {data: sites = [] } = useGetSitesQuery();
  const loggedInUser = useSelector(selectLoggedInUser);
  const atlasInstance = useSelector(selectAtlasInstance);
  const { data: instancePermission } = useGetUserInstancePermissionQuery(
    { user: loggedInUser, instanceName: atlasInstance || "" },
    { skip: !atlasInstance }
  );
  const checkPlanPermissions = usePermissions(FeatureType.PLAN);
  const userCanReadPlans = checkPlanPermissions(ActionType.READ);
  const userCanViewIssueDashboard = usePermissions(FeatureType.ISSUES_DASHBOARD)(ActionType.READ);
  const userCanAccessProjects = usePermissions(FeatureType.PROJECT)(ActionType.READ);
  const userCanAccessZippy = usePermissions(FeatureType.ZIPPY)(ActionType.READ);
  const userCanAccessUsers = usePermissions(FeatureType.USER)(ActionType.READ);
  const userCanAccessPermissions = usePermissions(FeatureType.PERMISSION)(ActionType.READ);
  const userCanAccessMaterials = usePermissions(FeatureType.MATERIAL)(ActionType.READ);
  const userCanAccessEmployees = usePermissions(FeatureType.EMPLOYEE)(ActionType.READ);
  const userCanAccessTraining = usePermissions(FeatureType.QUALIFICATION)(ActionType.READ);
  const userCanAccesSkills = usePermissions(FeatureType.SKILLS)(ActionType.READ)
  const userCanAccessBKM = usePermissions(FeatureType.BKM)(ActionType.READ);
  const userCanAccessSites = usePermissions(FeatureType.SITE)(ActionType.READ);

  const dashboardsRoutes = {
    id: "Dashboard",
    path: "/dashboard",
    icon: <Tune />,
    component: null,
    containsHome: true,
    children: [
      {
        path: "/dashboard/issues",
        name: "Issues",
        component: IssuesDashboard,
        visible: userCanViewIssueDashboard,
      },
    ],
  };

  const projectsRoutes = {
    id: "Projects",
    path: "/projects",
    icon: <Work />,
    component: userCanAccessProjects ? Projects : null,
    children: null,
  };

  const plansRoutes = {
    id: "Planning",
    path: "/plans",
    icon: <Assignment />,
    component: userCanReadPlans ? Plans : null,
    children: [
      {
        path: "/plans",
        name: "Plans",
        component: Plans,
        visible: userCanReadPlans,
        children: [
          {
            path: `/plans/createPlan`,
            name: "Create Plan",
            component: CreatePlan,
            visible: userCanReadPlans,
          },
          {
            path: `/plans/:planId`,
            name: "View Plan",
            component: Plan,
            visible: userCanReadPlans,
          },
        ]
      },
      {
        path: "/tasks",
        name: "Tasks",
        component: Tasks,
        visible: userCanReadPlans,
      },   
      {
        path: "/presets",
        name: "Presets",
        component: PlanPresets,
        visible: ["SUPER USER", "ADMINISTRATOR"].includes(instancePermission?.userRole || ''),
      },     
    ],
  };

  const zippyRoutes = {
    id: "Zippy",
    path: "/zippy",
    icon: <ElectricBolt />,
    component: userCanAccessZippy ? Zippy : null,
    children: null,
  };

  const authRoutes = {
    id: "Auth",
    path: "/auth",
    icon: <Group />,
    children: [
      {
        path: "/auth/sign-in",
        name: "Sign In",
        component: SignIn,
        visible: true,
      },
      {
        path: "/auth/404",
        name: "404 Page",
        component: Page404,
        visible: true,
      },
      {
        path: "/auth/500",
        name: "500 Page",
        component: Page500,
        visible: true,
      },
    ],
    component: null,
  };

  const systemRoutes = {
    id: "System",
    path: "/system",
    icon: <SettingWheel />,
    children: [
      {
        path: "/system/users",
        name: "Users",
        component: UsersPage,
        visible: userCanAccessUsers,
      },
      {
        path: "/system/permissions",
        name: "Permissions",
        component: AppPermissions,
        visible: userCanAccessPermissions,
      },
      {
        path: "/system/profile",
        name: "Profile",
        component: Profile,
        visible: true,
      },
    ],
    component: null,
  };

  const inventoryRoutes = {
    id: "Inventory",
    path: "/inventory",
    icon: <Layers />,
    children: [
      {
        path: "/inventory/materials",
        name: "Materials",
        component: MaterialsPage,
        visible: userCanAccessMaterials,
      },
    ],
    component: null,
  };

  const teamRoutes = {
    id: "Team",
    path: "/team",
    icon: <Group />,
    children: [
      {
        path: "/team/employees",
        name: "Employees",
        component: Employees,
        visible: userCanAccessEmployees,
      },
      // {
      //   path: "/team/choochoo",
      //   name: "Choo Choo",
      //   component: ChooChoo,
      //   visible: userCanAccessChooChoo,
      // },
      {
        path: "/team/manage",
        name: "Available Training",
        component: AvailableTraining,
        visible: userCanAccessTraining,
      },
      {
        path: "/team/skillsMatrix",
        name: "Skills Matrix",
        component: SkillsMatrix,
        visible: userCanAccesSkills,
      },
      {
        path: "/team/skillSearch",
        name: "Skill Search",
        component: SkillSearch,
        visible: userCanAccesSkills,
      },
    ],
    component: null,
  };

  const learningRoutes = {
    id: "Learning",
    path: "/learning",
    icon: <MenuBook />,
    children: [
      {
        path: "/learning/bestKnownMethods",
        name: "BKM",
        component: BestKnownMethods,
        visible: userCanAccessBKM,
      },
    ],
    component: null,
  };

  const sitesRoutes = {
    id: "Sites",
    path: "/site",
    icon: <ViewCompact />,
    children: sites
      .map((site) => ({
        path: `/site/${site.name}`,
        name: site.name,
        component: ShopMap,
        visible: userCanAccessSites,
        props: { site: site },
      }))
      .sort(alphabetizeByProperty("name")),
    component: null,
  };

  const projectRoutes = {
    id: "Project",
    path: "/projects",
    icon: <Assignment />,
    children: [
      {
        path: `/projects/:projectId`,
        name: "View Project",
        component: Project,
        visible: userCanAccessProjects,
      },
    ],
    component: null,
  };

  return {
    dashboardLayoutRoutes: getDashboardLayoutRoutes(),
    authLayoutRoutes: [authRoutes],
    sidebarRoutes: getSideNavRoutes(),
  };

  function getDashboardLayoutRoutes() {
    const dashboardLayoutRoutes = [
      dashboardsRoutes,
      sitesRoutes,
      zippyRoutes,
      projectsRoutes,
      projectRoutes,
      plansRoutes,
      inventoryRoutes,
      systemRoutes,
      teamRoutes,
      learningRoutes,
    ];
    dashboardLayoutRoutes.forEach((route, index) => {
      if (route.children?.length) {
        dashboardLayoutRoutes[index].children = route.children.filter(
          (subRoute) => subRoute?.visible
        );
      }
    });
    return dashboardLayoutRoutes;
  }

  function getSideNavRoutes() {
    // addExternalRoutes();

    const sideNavRoutes = [
      dashboardsRoutes,
      sitesRoutes,
      zippyRoutes,
      projectsRoutes,
      plansRoutes,
      inventoryRoutes,
      teamRoutes,
      systemRoutes,
      learningRoutes,
    ];
    return sideNavRoutes.filter(
      (route) => route?.component || route.children?.length
    );
  }

  function addExternalRoutes() {
    learningRoutes.children.push({
      path: "https://atlas-global-docs-web.herokuapp.com/",
      name: "Documentation",
      component: ExternalRoute,
      visible: true,
    });
  }
};
